import axios from "axios";
import ToastComp from "components/toast/ToastComp";
// const axiosServices = axios.create();

// axiosServices.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
// );


// export default axiosServices
// http://192.168.32.36:8080
//https://javaentrypanel.6lgx.com:8080
//https://javaentrypanel.6lgx.com:8080
// http://192.168.32.36:8080 latest local @naeem
// https://sportcccmsbackend.6lgx.com:8080
// REACT_APP_APIURL
// REACT_APP_STAGINGAPIURL
const client = axios.create({
  baseURL: process.env.REACT_APP_STAGINGAPIURL,
  headers: {
    authorization: "Basic dXNlcjpwYXNzd29yZA==",
  },
});


const rabbitMQClient = axios.create({
  baseURL: process.env.REACT_APP_RABBITMQURL,
  headers: {
    authorization: "Basic dXNlcjpwYXNzd29yZA==",
  },
});

// const client1 = axios.create({
//   baseURL: process.env.REACT_APP_STAGINGAPIURL,
//   headers: {
//     authorization: "Basic dXNlcjpwYXNzd29yZA==",
//   },
// });

const sofaClient = axios.create({
  baseURL: 'https://sofascores.p.rapidapi.com',
  headers: {
    "X-RapidAPI-Key": "79412969f8msh3dbeacb2532ca2bp1ad2b4jsnf5d9f64e4dd9",
    'X-RapidAPI-Host' : 'sofascores.p.rapidapi.com'
  },
});

export const requestSofa = async ({ ...options }, notify = false)  => {

  const onSuccess = (response) => {
    if (notify) { 
      if (response.status === 200) {
        if (options.method === "delete") {
          ToastComp({
            varient: "success",
            message: response.message
              ? response.message
              : "Removed Successfully",
          });
        } else if (options.method === "put") {
          if (notify) {
            ToastComp({
              varient: "success",
              message: response.message
                ? response.message
                : "Updated Successfully",
            });
          }
        } else if (options.method === "post") {
          if (notify) {
            ToastComp({
              varient: "info",
              message: response.message ? response.message : "Already Added",
            });
            return response;
          } else {
            ToastComp({
              varient: "info",
              message: response.message ? response.message : "Already Added",
            });
          }
          return response;
        } else if (options.method === "patch") {
          if (notify) {
            ToastComp({
              varient: "success",
              message: response.message
                ? response.message
                : "Updated Successfully",
            });
          }
        }
        return response;
      } else if (response.status === 201) {
        if (notify == true) {
          ToastComp({
            varient: "success",
            message: response.message ? response.message : "Added Successfully",
          });
          return response;
        } else {
          return response;
        }
      } else {
        ToastComp({
          varient: "error",
          message: response.message ? response.message : "Error",
        });
        return response;
      }
    } else {
      return response?.data;
    }
  };

  const onError = (error) => {
    console.log(
      "Error In Axios interceptor : ",
      error,
      error?.response?.data?.message
    );
    if (notify) {
      ToastComp({
        varient: "error",
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Error ! Try Again Later",
      });
      return error;
    }
    return error;
  };
  
  return sofaClient(options).then(onSuccess).catch(onError);
}

export const request = async ({ ...options }, notify = true) => {

  const onSuccess = (response) => {
    if (notify) { 
      if (response.status === 200) {
        if (options.method === "delete") {
          ToastComp({
            varient: "success",
            message: response.message
              ? response.message
              : "Removed Successfully",
          });
        } else if (options.method === "put") {
          if (notify) {
            ToastComp({
              varient: "success",
              message: response.message
                ? response.message
                : "Updated Successfully",
            });
          }
        } else if (options.method === "post") {
          if (notify) {
            ToastComp({
              varient: "info",
              message: response.message ? response.message : "Already Added",
            });
            return response;
          } else {
            ToastComp({
              varient: "info",
              message: response.message ? response.message : "Already Added",
            });
          }
          return response;
        } else if (options.method === "patch") {
          if (notify) {
            ToastComp({
              varient: "success",
              message: response.message
                ? response.message
                : "Updated Successfully",
            });
          }
        }
        return response;
      } else if (response.status === 201) {
        if (notify == true) {
          ToastComp({
            varient: "success",
            message: response.message ? response.message : "Added Successfully",
          });
          return response;
        } else {
          return response;
        }
      } else {
        ToastComp({
          varient: "error",
          message: response.message ? response.message : "Error",
        });
        return response;
      }
    } else {
      return response;
    }
  };

  const onError = (error) => {
    console.log(
      "Error In Axios interceptor : ",
      error,
      error?.response?.data?.message
    );
    if (notify) {
      ToastComp({
        varient: "error",
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Error ! Try Again Later",
      });
      return error;
    }
    return error;
  };

  // if(options?.url?.includes('tennis')){
  //   return client1(options).then(onSuccess).catch(onError);
  // }

  return client(options).then(onSuccess).catch(onError);
};


export const requestMq = async ({ ...options }, notify = true) => {

  const onSuccess = (response) => {
    return response;
  };
  
  const onError = (error) => {
    console.log(
      "Error In Axios interceptor : ",
      error,
      error?.response?.data?.message
    );
    if (notify) {
      ToastComp({
        varient: "error",
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Error ! Try Again Later",
      });
      return error;
    }
    return error;
  };

  return rabbitMQClient(options).then(onSuccess).catch(onError);
}
// Bulk Requests
const clientAll = axios.create({
  baseURL: "https://javaentrypanel.6lgx.com:8080",
  headers: {
    authorization: "Basic dXNlcjpwYXNzd29yZA==",
  },
});

export const requestAll = async ({ ...options }) => {
  const onSuccess = (response) => {
    return response;
  };
  const onError = (error) => {
    console.log("Error In Axios interceptor : ", error);
    return error;
  };

  return clientAll(options).then(onSuccess).catch(onError);
};
